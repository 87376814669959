var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.value.length)?_c('section',{staticClass:"post-area-info"},[_c('div',{staticClass:"post-area-info__items"},_vm._l((_vm.value),function(item,i){return _c('div',{key:i,staticClass:"post-area-info__item"},[_c(_setup.RichText,{staticClass:"post-area-info__item-content container",attrs:{"value":item.content,"text-align":"center"}}),_c(_setup.Gallery,{staticClass:"container",attrs:{"value":item.images,"columns":2,"gap":"1.5rem","layout":[
          { cols: 2 },
          { cols: 1 },
          { cols: 1 },
          { cols: 1 },
          { cols: 1 },
          { cols: 2 },
          { cols: 2 },
        ]}})],1)}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }