<template>
  <Lead
    class="project-lead"
    :project="item.id"
    :reference="{ onModel: 'Project', ref: item.id }"
    :fields="['!title', '!comment', '!contact.upsell']"
    action-label="Send til utleier"
    :placeholders="{
      'contact.name': 'Navn',
      'contact.email': 'E-post',
      'contact.phone': 'Telefon',
    }"
  />
</template>

<script>
import { mapState } from 'vuex'
import Lead from '@kvass/lead'

export default {
  computed: {
    ...mapState('Project', ['item']),
  },
  components: {
    Lead,
  },
}
</script>

<style lang="scss">
.project-lead {
  max-width: 800px;
  margin: 0 auto;

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: inherit;
  }

  ::placeholder {
    color: inherit;
    opacity: 1; /* Firefox */
  }
  .lead__form {
    background-color: transparent;

    .alert__content {
      color: $dark !important;
    }

    &-actions {
      justify-content: start;
    }

    .elder-input__label {
      display: none;
    }
  }
}
</style>
