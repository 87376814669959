<template>
  <div class="hero" v-if="items.length">
    <div class="hero__slide">
      <Slider :value="items" :display-thumbnail="true" :manual-navigation="true" />
    </div>

    <div class="hero__content container">
      <RichText class="hero__slogan" :value="description || item.description" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ScrollIntoView } from '@/utils'
import Slider from '@kvass/media-render/Slider'
import RichText from '@/components/RichText'

export default {
  props: {
    description: {
      type: String,
    },
    images: {
      type: Array,
      default: () => [],
    },
    stats: {
      type: Array,
    },
    useParallax: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parallax: 0,
      ticking: false,
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    items() {
      return this.images || []
    },
    upcomingLabel() {
      return KvassConfig.get('customLabels.upcoming') || this.$t('upcomingLabel')
    },
    enabledStatus() {
      return [
        { key: 'sale', type: 'sale' },
        { key: 'sold', type: 'sale' },
        { key: 'rent', type: 'rent' },
        { key: 'rented', type: 'rent' },
        { key: 'reserved', type: 'sale' },
        { key: 'upcoming', type: 'sale' },
      ].filter(e => this.getStat(e.key))
    },
  },
  methods: {
    gotoInfo() {
      ScrollIntoView('.section__info', -document.querySelector('.nav').clientHeight)
    },
    getStat(key) {
      let keys = key instanceof Array ? key : [key]

      return keys.reduce((r, c) => r + KvassConfig.get(`stats.${c}`) || 0, 0)
    },
    onScroll() {
      if (!this.useParallax) return
      requestAnimationFrame(() => {
        const scrollTop = window.scrollY / 4
        document.body.style.setProperty('--parallax-top', `${scrollTop}px`)
      })
    },
  },
  metaInfo() {
    return {
      link: [...this.items.map(item => ({ rel: 'preload', as: 'image', href: item }))],
    }
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll)
  },
  components: {
    Slider,
    RichText,
  },
}
</script>

<style lang="scss">
.hero {
  $height: 70vh;

  position: relative;
  height: $height;
  width: 100%;
  background-color: css-alpha('primary', 0.1);

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 500ms;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  &__content {
    position: absolute;
    inset: 0;
    padding: 0 !important;

    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  &__slogan {
    height: 100%;
    width: 100%;
    color: white;
    text-shadow: 0 2px 4px rgba(black, 0.5);

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    @include respond-below('phone') {
      font-size: 0.8rem;
    }

    & > * {
      max-width: 800px;
    }

    & > h2 {
      margin: 0;
      font-weight: 900;
      line-height: 1.1;
      font-size: 4em;
    }

    & > p {
      font-size: 1.25em;
    }
  }

  &__slide {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;

    iframe {
      min-height: $height;
    }

    .kvass-media-render-slider {
      .kvass-media-render-image {
        top: var(--parallax-top, 0);
      }

      &__navigation {
        align-items: end;
        justify-content: end;
        pointer-events: initial;
        z-index: 2;

        &-button {
          background-color: white;
          color: black;
          padding: 0.5rem 1rem;

          @include respond-below('phone') {
            padding: 1rem 1rem;
          }

          &:hover {
            background-color: #f5f5f5;
          }
        }
      }
    }
  }
}
</style>
