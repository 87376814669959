<template>
  <div v-if="value.length" class="roles">
    <!-- <h2 class="roles__title">{{ $tc('getInTouch', 2) }}</h2> -->
    <strong class="roles__title" v-if="title">{{ title }}</strong>
    <div class="roles__list">
      <div
        class="roles__item"
        v-for="(item, index) in value"
        :key="index"
        :data-id="item.id"
        :data-email="item.email"
      >
        <div class="roles__avatar" :style="{ backgroundImage: `url(${item.avatar})` }"></div>
        <div class="roles__content">
          <div class="roles__name">{{ item.name }}</div>
          <div v-if="item.title" class="roles__user-title">{{ item.title }}</div>
          <a v-if="item.phone" class="roles__phone" :href="`tel:${item.phone}`">{{ item.phone }}</a>
          <a v-if="item.email" class="roles__email" :href="`mailto:${item.email}`">
            {{ item.email }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    title: String,
  },
}
</script>

<style lang="scss">
.roles {
  &__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }

  &__title {
    margin-bottom: 1.5rem;
  }

  &__avatar {
    --_avatar-size: 200px;

    height: var(--avatar-size, var(--_avatar-size));
    width: var(--avatar-size, var(--_avatar-size));
    border-radius: 100rem;
    border: 1px solid var(--primary);
    background-size: cover;
    background-position: center;

    flex-shrink: 0;
  }

  &__subtitle {
    color: rgba($dark, 0.6);
  }

  &__user-title {
    margin: 2px 0;
  }

  &__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    text-align: center;

    border-radius: $border-radius;

    @include respond-below('phone') {
      .roles {
        &__name {
          margin-top: 10px;
        }
      }
    }
  }

  &__name {
    font-weight: bold;
    font-size: 1.1em;
  }

  &__email,
  &__phone {
    display: block;
    opacity: 0.6;
    text-decoration: none;
    white-space: nowrap;

    margin-right: 10px;

    svg {
      margin-right: 7px;
    }
  }
}
</style>
