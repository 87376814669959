<template>
  <div
    id="app"
    :class="['route--' + view.toLowerCase(), isOnTop ? 'page--is-top' : 'page--is-scrolled']"
  >
    <div class="demo-indicator" v-if="isDemo">
      <FontAwesomeIcon icon="exclamation-circle" />
      <span>{{ $t('thisIsADemo') }}</span>
    </div>
    <div
      v-if="$route.meta.navigation !== false"
      class="nav"
      :class="[
        `nav--${view.toLowerCase()}`,
        { [`nav--${view.toLowerCase()}-expanded`]: isExpanded },
      ]"
    >
      <NavigationComponent
        :logo="logo"
        :title="projectName"
        padding="1rem"
        :action="mainAction"
        :isOpen.sync="isExpanded"
        :break-at="1"
        :animate="false"
      >
        <template #center>
          <div class="nav__meta">
            <h2 class="nav__meta-title">{{ item.name }}</h2>
            <p class="nav__meta-address">{{ address }}</p>
          </div>
          <div class="nav__actions">
            <ButtonComponent
              tag="router-link"
              to="#kontakt"
              class="elder__navigation-component--primary"
              theme="primary"
              label="Utleier"
            />
          </div>
        </template>
      </NavigationComponent>
    </div>
    <transition mode="out-in" name="route">
      <router-view />
    </transition>
    <FooterComponent v-if="$route.meta.footer !== false" theme="mini" :cookie-manager="CM" />
    <AssetComponent />
  </div>
</template>

<script>
import { NavigationComponent } from 'vue-elder-navigation'
import { mapState, mapActions } from 'vuex'
import AssetComponent from '@kvass/asset-manager'
import FooterComponent from '@/components/Footer'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'
import loadCM from './gdpr'

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
      CM: {},
    }
  },
  computed: {
    ...mapState('Root', ['item', 'promises']),
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },
    isSingular() {
      return API.projectType === 'singular'
    },
    view() {
      if (!this.isSingular) return this.$path('name', this.$route) || ''
      return 'ProjectResidential'
    },
    logo() {
      return this.logos.dark
    },
    isDemo() {
      return this.item.demo || false
    },
    labels() {
      return KvassConfig.get('customLabels') || {}
    },
    projectName() {
      return this.item.name || ''
    },
    address() {
      let address = this.$path('address', this.item) || {}
      return `${address.street || ''}, ${address.postcode || ''} ${address.city || ''}${
        address.county ? ', ' + address.county : ''
      }`
    },
    seo() {
      return this.$path('customFields.seo', this.item) || {}
    },
    hasFlatfinder() {
      return this.$path('flatfinders.length', this.item)
    },
    hasStatsTotal() {
      return this.$path('stats.total', this.item)
    },
    hasResidentials() {
      return this.$path('$store.state.Residentials.item.docs.length')
    },
    hasAttachments() {
      if (!this.isSingular) return this.$path('media.attachments.length', this.item)
      return this.$path('$store.state.Residential.item.media.attachments.length')
    },
    brochure() {
      return this.$path('media.brochure', this.item) || []
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    posts() {
      return this.$path('posts', this.item) || []
    },
    gallery() {
      if (!this.isSingular) return this.$path('media.gallery', this.item) || []
      return this.$path('$store.state.Residential.item.media.gallery') || []
    },

    addons() {
      return this.$path('item.metadata.addons') || []
    },
    redirectUrl() {
      return (
        this.$path('item.publishing.redirectUrl') &&
        `https://${this.$path('item.publishing.redirectUrl')}`
      )
    },

    mainAction() {
      if (this.addons.includes('project:flatfinder')) return this.redirectUrl
      if (this.isSingular) return
      return this.redirect('Project', { scrollTo: 0 })
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    redirect(name, options = { forceRedirect: false }) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params, forceRedirect } = options

        if (!name.includes(this.$route.name) || params?.slug != this.$route.query?.slug)
          await this.$router.push({ name: name[0], hash, params })
        else if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
  },
  created() {
    const debounce = fn => {
      let frame
      return (...params) => {
        if (frame) cancelAnimationFrame(frame)
        frame = requestAnimationFrame(() => fn(...params))
      }
    }

    const storeScroll = () => {
      this.isOnTop = window.scrollY <= 5
    }

    document.addEventListener('scroll', debounce(storeScroll), { passive: true })
    storeScroll()

    this.promise = this.fetch().then(() => (this.CM = loadCM(this)))
  },
  metaInfo() {
    return {
      titleTemplate: this.projectName
        ? `%s | ${this.$path('seo.title') || this.projectName}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        { rel: 'preload', href: this.logos.dark, as: 'image' },
        { rel: 'preload', href: this.logos.light, as: 'image' },
        { rel: 'icon', href: API.favicon, type: 'image/png' },
      ],
    }
  },
  components: {
    NavigationComponent,
    FooterComponent,
    AssetComponent,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: get-layer('nav');
  background: white;

  .elder__navigation {
    @include respond-below('tablet') {
      flex-direction: column;
      align-items: flex-start;
    }

    &-component {
      &--primary {
        &.elder-button {
          border: none;
          --vue-elder-button-padding-y: 0.5rem;
          --vue-elder-button-padding-x: 1rem;
        }
      }
    }

    &-logo {
      &-image {
        max-width: 20vw;
        max-height: 40px !important;
      }

      @include respond-below('tablet') {
        display: none;
      }
    }
  }

  &__meta {
    text-align: center;
    flex-grow: 1;

    &-title {
      color: var(--custom-color);
      margin: 0;
    }

    &-address {
      text-transform: uppercase;
      color: $light-grey;
      margin: 0;
      font-size: 0.9rem;
      opacity: 0.6;
    }

    @include respond-below('tablet') {
      text-align: start;
      font-size: 0.8rem;
    }

    @include respond-below('phone') {
      & + .elder__navigation-component--primary {
        width: 100%;
        display: block;
        padding-block: 0.5rem;
        text-align: center;
        margin-top: 1rem;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    @include respond-below('phone') {
      margin-top: 0.5rem;
    }
  }

  @include respond-below('phone') {
    position: fixed;
  }
}

.route-enter,
.route-leave-to {
  opacity: 0;
  transform: scale(0.98);
}

.route-enter-to,
.route-leave {
  opacity: 1;
  transform: scale(1);
}

.route-enter-active,
.route-leave-active {
  transform-origin: top;
  transition: 500ms ease;
}
</style>
