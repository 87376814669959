<template>
  <MglMap
    v-if="$attrs.center"
    class="mgl-map"
    v-bind="{ ...$attrs, ...defaults }"
    :mapbox-gl="mapbox"
    @load="onLoad"
  >
    <slot></slot>
    <MglMarker v-if="$attrs.center" :coordinates="$attrs.center" color="var(--primary)" />
    <MglNavigationControl :show-zoom="true" position="top-right" />
  </MglMap>
</template>

<script>
import Mapbox from 'mapbox-gl'
import { MglMap, MglMarker, MglNavigationControl } from 'vue-mapbox'
import { Clone } from '@/utils'
import BrowserApiMixin from '@/mixins/browser-api'

const mapDefaults = {
  zoom: 14,
  center: [10.7522, 59.9139],
  accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
  mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
  dragPan: true,
  scrollZoom: false,
  showZoom: true,
}

export default {
  mixins: [BrowserApiMixin('map')],
  data() {
    return {
      mapbox: null,
      mapDefaults: Clone(mapDefaults),
      isHovering: false,
    }
  },
  computed: {
    defaults() {
      return Object.entries(this.mapDefaults).reduce((res, [key, value]) => {
        res[key] = this.$attrs[key] || value
        return res
      }, {})
    },
  },
  methods: {
    animate(timestamp = 0) {
      this.map.rotateTo((timestamp / 100) % 360, { duration: 0 })
      requestAnimationFrame(() => this.animate(timestamp + 1))
    },
    onLoad({ map }) {
      this.map = map
      // this.animate()
    },
  },
  created() {
    this.map = null
    this.mapbox = Mapbox
  },
  components: {
    MglMap,
    MglMarker,
    MglNavigationControl,
  },
}
</script>

<style lang="scss">
.mgl-map {
  border-radius: $border-radius;
  overflow: hidden;

  height: auto;
  width: auto;
  min-height: 600px;
  max-height: 800px;
}

.mapboxgl-marker svg g g:nth-child(2) {
  fill: var(--custom-color);
}

.mapboxgl-ctrl-attrib {
  display: none;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}
</style>
