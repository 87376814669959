<template>
  <footer class="footer" :class="`footer--theme-${theme}`">
    <div class="footer__content container">
      <FooterContent
        v-if="customFooterContent.length"
        :value="customFooterContent"
        class="footer__custom-content"
      />
      <div class="footer__container">
        <div>
          <div class="footer__copyright">{{ year }} &copy; Storebrand</div>
          <router-link class="footer__links" to="/forbehold">Forbehold</router-link>
          <div class="footer__links">
            <router-link :to="`/api/legal/privacy/project/${item.id}`" target="_blank">
              {{ $t('privacyPolicy') }} / {{ $t('cookiePolicy') }}
            </router-link>

            <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
          </div>
        </div>

        <span>
          {{ $t('pageMadeBy') }}
          <a :href="`https://kvass.no?ref=${currentUrl}`">Kvass</a>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api'
import { GetCustomField } from '@/utils'

import FooterContent from '@kvass/template-footer-content'
import ActionComponent from 'vue-elder-navigation/src/action'
import SoMeFooter from '@/components/SoMeFooter.vue'

export default {
  props: {
    navigation: Array,
    theme: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'compact', 'mini'].includes(value)
      },
    },
    cookieManager: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    customFooterContent() {
      if (!this.item) return
      return GetCustomField('footer-content', this.item.customFields) || []
    },
    someCustomField() {
      if (!this.item) return
      return GetCustomField('social-media', this.item.customFields) || []
    },
    logo() {
      //return logo based on primary-color lightness
      let backgroundLightness = getComputedStyle(document.documentElement).getPropertyValue(
        '--primary-l',
      )
      if (parseFloat(backgroundLightness) < 70) return API.logoInverted
      return API.logo
    },
    currentUrl() {
      return window.location.href
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
  },
  components: {
    ActionComponent,
    FooterContent,
    SoMeFooter,
  },
}
</script>

<style lang="scss">
.footer {
  &__logo {
    margin-bottom: 2rem;
  }

  &__content {
    border-top: 1px solid #d0d0d0;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-wrap: wrap;
    gap: 2rem;
  }

  &__copyright {
    a {
      font-weight: bold;
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }

  p {
    margin-top: 0;
  }

  &__custom-content {
    margin-bottom: 2rem;
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    gap: 0.5rem;
  }

  &__social-icons {
    a {
      margin-right: 0.8rem;
    }
  }

  /* Themes */
  &--theme {
    &-compact {
      .footer__content {
        padding-block: 3rem;
      }

      .footer__bottom {
        padding-block: 1rem;
      }
    }

    &-mini {
      .footer__content {
        padding-block: 2rem;
      }
    }
  }
}
</style>
