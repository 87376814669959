<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <Hero class="section__hero" :images="$path('media.cover', item)">
        <template #stats-item="{ item }">
          <RichText :value="item" />
        </template>
      </Hero>

      <!-- INTRO & NØKKELINFORMASJON -->
      <Section theme="secondary">
        <ScrollAnchor value="info" offset="-8rem" />
        <Grid :columns="2" align="start" gap="1rem">
          <RichText
            v-if="richTextNotEmpty(item.description)"
            :value="TransformTag(item.description)"
          />
          <Card v-if="richTextNotEmpty(keyInfo)" title="Nøkkelinfo">
            <template v-for="item in keyInfo">
              <p>
                <b> {{ item[0] }}:</b>
                {{ item[1] }}
              </p>
            </template>
          </Card>
        </Grid>
      </Section>

      <Section v-if="gallery.length">
        <ScrollAnchor value="lokaler" offset="-8rem" />
        <Gallery :value="gallery" :columns="1" />
      </Section>

      <Section v-if="(images[0] || []).length">
        <Masonry v-for="(item, index) in images" :key="index" :value="item" />
      </Section>

      <!-- BELIGGENHET -->
      <Section v-if="locationText">
        <Grid :columns="2">
          <RichText v-if="locationText" :value="locationText" />
          <Card v-if="locationFeatures.length" theme="secondary" title="Adkomst">
            <Features
              :value="locationFeatures"
              padding="1rem"
              background="transparent"
              align="center"
              :columns="3"
            />
          </Card>
        </Grid>
      </Section>

      <Section v-if="$path('address', item)">
        <ScrollAnchor value="kart" offset="-8rem" />
        <Map :value="$path('address', item)" />
      </Section>

      <!-- PLANTEGNINGER -->
      <Section v-if="$path('images.length', plans)">
        <Grid v-if="richTextNotEmpty(plans.content)" :columns="2">
          <RichText :value="plans.content" />
        </Grid>
        <Masonry :value="plans.images" class="plans" />
      </Section>

      <!-- FASILITETER -->
      <Section
        v-if="richTextNotEmpty(facilitiesText)"
        containerStyle="display:flex; flex-direction:column; gap:1rem;"
      >
        <ScrollAnchor value="fasiliteter" offset="-8rem" />
        <RichText :value="facilitiesText" width="55ch" class="mb-2" />
        <FacilitiesFeatures :value="facilitiesFeatures" />
      </Section>
      <Section v-if="(facilitiesImages[0] || []).length">
        <Masonry
          v-for="(item, index) in facilitiesImages"
          :key="`faceilities-images_${index}`"
          :value="item"
        />
      </Section>

      <!-- MILJØ -->

      <Section v-if="environment.content || (environment.images && environment.images.length)">
        <Grid :columns="2" gap="2rem" style="align-items: center">
          <RichText :value="environment.content" />
          <Masonry :value="environment.images" />
        </Grid>
      </Section>

      <!-- GÅRDEIER -->
      <Section v-if="owner.content || owner.logo">
        <ScrollAnchor value="gardeier" offset="-8rem" />
        <Grid :columns="2" gap="2rem" style="align-items: center">
          <RichText :value="owner.content" />
          <ImageComponent
            :value="owner.logo"
            size="contain"
            style="max-width: 300px; margin-left: auto"
          />
        </Grid>
      </Section>

      <!-- KONTAKT MEGLER -->
      <Section>
        <ScrollAnchor value="kontakt" offset="-8rem" />
        <RichText v-if="richTextNotEmpty(leadContent)" :value="leadContent" width="55ch" />
        <Grid :columns="2" align="start">
          <Roles :value="$path('roles.salesman', item) || []" class="mt-2" />
          <Lead />
        </Grid>
      </Section>
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { GetCustomField, TransformTag } from '@/utils'
import BrowserApiMixin from '@/mixins/browser-api'

import ScrollAnchor from '@kvass/scroll-anchor'
import ImageComponent from '@kvass/media-render/Types/Image'

import Masonry from '@/components/Masonry'
import Hero from '@/components/Hero'
import RichText from '@/components/RichText'
import Gallery from '@/components/Gallery'
import Grid from '@/components/Grid'
import Section from '@/components/Section'
import Card from '@/components/Card'
import AreaInfo from '@/components/AreaInfo'
import Features from '@/components/Features'
import FacilitiesFeatures from '@/components/FacilitiesFeatures'
import Map from '@/components/Map'
import Roles from '@/components/Roles'
import Lead from '@/components/Lead'

export default {
  mixins: [BrowserApiMixin('project')],
  data() {
    return {
      iconMap: {
        flexibleSolutions: { icon: ['fa', ''], size: '2x' },
      },
    }
  },
  computed: {
    ...mapState('Project', ['promises', 'item']),
    additionalInfo() {
      return this.$path('item.customFields.additional-info') || []
    },
    rentProperties() {
      return this.$path('item.customFields.rent-properties') || {}
    },
    rentLocation() {
      return this.$path('item.customFields.rent-location') || {}
    },
    projectRentProperties() {
      return this.$path('item.customFields.project-rent-properties') || {}
    },
    keyInfo() {
      const customIncludedKeys = [
        'owner',
        'numberOfRooms',
        'numberOfParkingSpots',
        'energyLabel',
        'energyColor',
        'constructionYear',
        'renovationYear',
      ]
      const includedKeys = [
        'propertyType',
        'totalArea',
        'acquisition',
        'officeSpaces',
        'owner',
        'address',
        'location',
        'floor',
        'price',
        'environmentallyCertified',
        ...customIncludedKeys,
      ]

      let address = this.$path('address', this.item) || {}
      address = `${address.street || ''}, ${address.postcode || ''} ${address.city || ''}${
        address.county ? ', ' + address.county : ''
      }`

      function filterNumber(number) {
        if (!number) return
        return number.toLocaleString().replace(',', '.')
      }

      let totalArea = () => {
        let data = this.projectRentProperties

        if (!data || !data.areaFrom) return

        return `${this.$tc('from')} ${filterNumber(data.areaFrom)} ${
          data.areaTo ? this.$tc('to').toLowerCase() + ' ' + filterNumber(data.areaTo) : ''
        } m²`
      }

      const entries = Object.entries({
        ...this.rentProperties,
        ...this.projectRentProperties,
        ...this.rentLocation,
        totalArea: totalArea(),

        address,
      }).filter(([key, value]) => includedKeys.includes(key) && !!value)

      const prefix = key => (key === 'propertyType' ? 'rent-properties-propertyType:' : '')
      const parseValue = (val, prefix = '') => {
        val = val instanceof Array ? val : [val]
        return val.map(v => this.$tc(`${prefix}${v}`)).join(', ')
      }

      return entries.map(([key, value]) => [
        this.$tc(`${!customIncludedKeys.includes(key) ? 'rent-properties-' : ''}${key}`),
        parseValue(value, prefix(key)),
      ])
    },
    propertyTypeFeatures() {
      return [
        {
          title: this.$t('rent-properties-flexibleSolutions'),
          preselectedIcon: ['fas', 'pen-ruler'],
        },
        {
          title: this.$t('rent-properties-environmentallyCertified'),
          preselectedIcon: ['fas', 'leaf'],
          subtitle: this.rentProperties.environmentallyCertified,
        },
        {
          title: this.$t('rent-properties-energyClass'),
          preselectedIcon: ['fas', 'circle-bolt'],
          subtitle: this.projectRentProperties.energyLabel,
        },
        {
          title: this.$t('rent-properties-yearOfConstruction'),
          preselectedIcon: ['fas', 'buildings'],
          subtitle: this.projectRentProperties.constructionYear,
        },
      ]
    },

    textContent() {
      return this.$path('item.customFields.text-content')
    },
    gallery() {
      if (this.$path('item.customFields.project-settings.hide-gallery')) return []
      return this.$path('item.media.gallery') || []
    },
    images() {
      return this.$path('item.customFields.images') || []
    },
    facilitiesText() {
      return this.$path('item.customFields.rent-facilities.content')
    },
    facilitiesImages() {
      return this.$path('item.customFields.facilities-images') || []
    },
    facilitiesFeatures() {
      return this.$path('item.customFields.rent-facilities.features') || []
    },
    environment() {
      return this.$path('item.customFields.rent-environment') || {}
    },

    moreImages() {
      return this.$path('item.customFields.more-images') || []
    },
    locationText() {
      return this.$path('item.customFields.rent-location.content') || ''
    },
    locationFeatures() {
      const iconsMap = {
        train: ['fas', 'train'],
        tram: ['fas', 'train-tram'],
        subway: ['fas', 'train-subway-tunnel'],
        parkingSpots: ['fas', 'square-parking'],
        bus: ['fas', 'bus'],
        plane: ['fas', 'plane'],
        shopping: ['fas', 'shopping-bag'],
        bicycle: ['fas', 'bicycle'],
        taxi: ['fas', 'taxi'],
      }

      return (this.$path('item.customFields.rent-location-features') || [])
        .map(f => {
          if (!(Object.values(f || {}) || []).some(i => Boolean(i))) return

          return {
            ...f,
            preselectedIcon: iconsMap[f['preselected-icon']],
          }
        })
        .filter(i => Boolean(i))
    },
    owner() {
      return this.$path('item.customFields.rent-owner') || {}
    },
    plans() {
      return this.$path('item.customFields.rent-floorplans') || {}
    },
    leadContent() {
      return this.$path('item.customFields.lead-content')
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
    TransformTag,
    richTextNotEmpty(value) {
      return value != '<p></p>'
    },
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    ScrollAnchor,
    ImageComponent,
    Masonry,
    Hero,
    RichText,
    Gallery,
    Grid,
    Section,
    Card,
    AreaInfo,
    Features,
    FacilitiesFeatures,
    Map,
    Gallery,
    Roles,
    Lead,
  },
}
</script>

<style lang="scss">
.project-page {
  margin-top: $nav-height;

  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }

  .plans {
    margin-top: 2rem;

    img {
      object-fit: contain !important;
    }

    .masonry {
      &__content {
        grid-template-columns: 1fr;
        gap: 2rem;
      }

      &__item {
        background: none;
      }
    }
  }

  .section {
    &--no-pb {
      padding-bottom: 0;
    }

    &--no-p {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
</style>
