<script setup>
import ImageComponent from '@kvass/media-render/Types/Image.vue'

const props = defineProps({
  columns: {
    type: Number,
    default: 2,
  },
  layout: {
    type: Array,
    default: () => [],
  },
  value: {
    type: Array,
    default: () => [],
  },
  gap: {
    type: String,
    default: '1rem',
  },
  captionPlacement: String,
})

function getLayout(index) {
  return props.layout[index] || { cols: 1, rows: 1 }
}
</script>

<template>
  <div v-if="value" class="image-gallery" :class="`gallery--length-${value.length}`">
    <ImageComponent
      v-for="(item, index) in value"
      v-bind="$attrs"
      :key="index"
      :value="item"
      :style="`grid-row: span ${getLayout(index).rows}; grid-column: span ${getLayout(index).cols}`"
      :caption-placement="captionPlacement"
      caption-visibility="visible"
    />
  </div>
</template>

<style lang="scss">
.image-gallery {
  display: grid;
  grid-template-columns: repeat(v-bind(columns), 1fr);
  gap: v-bind(gap);
  padding-block: 0.5rem;

  @include respond-below('tablet') {
    grid-template-columns: 1fr;
  }

  .kvass-media-render-image {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @include respond-below('tablet') {
      grid-column: span 1 !important;
    }
  }
}
</style>
